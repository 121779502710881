import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css'; // You can import your header's CSS here
import brandImage from './brand-logo.png';


function Header() {
  const location = useLocation();
  function isCurrentPath(path){
    return location.pathname === path;
  }
  return (
    <header className="header">

      <div className='brand-image'>
      <Link to="/" className={isCurrentPath('/')?"nav-link active": "nav-link"}>
      <img src={brandImage} alt='brand-logo'></img>
      </Link>
      
      </div>
      <nav className="nav">
        <ul className="nav-list">
          <li className="nav-item">
            <Link to="/" className={isCurrentPath('/')?"nav-link active": "nav-link"}>Home</Link>
          </li>
          <li className="nav-item">
            <Link to="/impacts" className={isCurrentPath('/impacts')?"nav-link active": "nav-link"}>Impact</Link>
          </li>
          <li className="nav-item">
            <Link to="/faq" className={isCurrentPath('/faq')?"nav-link active": "nav-link"}>FAQS</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
