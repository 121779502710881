import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Impacts from './pages/Impacts';
import PrivacyPolicy from './pages/Privacy';
import Terms from './pages/terms';
import FAQ from './pages/faq';
import Promo from './pages/promo';
// import Contact from './pages/Contact';
import Footer from './components/Footer';
import './App.css'; // You can import your app's CSS here

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
      <Routes>
          <Route path="/" exact element ={<Home/>} />
          <Route path="/impacts" element ={<Impacts/>} />
          <Route path="/privacy" element ={<PrivacyPolicy/>} />
          <Route path="/#/privacy" element ={<PrivacyPolicy/>} />
          <Route path="/terms" element ={<Terms/>} />
          <Route path="/#/terms" element ={<Terms/>} />
          <Route path="/faq" element ={<FAQ/>} />
          <Route path="/giftathon" element ={<Promo/>} />
          <Route path="/#/faq" element ={<FAQ/>} />
        </Routes>
      <Footer/>
      </div>
    </Router>
  );
}

export default App;
