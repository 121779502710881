import React from 'react';
import CumGraph from '../components/CumGraph';
import './impacts.css';

function Impacts() {
  const impacts = [
    {'title': 'Water Savings:', 'description':'Every time you share an item through Wura, you\'re helping conserve precious water resources. Producing and manufacturing goods require significant amounts of water, and by reusing items, you\'re reducing the demand for new production. Our impact chart quantifies the gallons of water you\'ve saved, giving you a tangible measure of your contribution to water conservation.'},
    {'title': 'Reducing Greenhouse Gases (GHG):', 'description':'Manufacturing and transportation processes associated with new products contribute to the release of greenhouse gases into the atmosphere. These gases contribute to climate change and environmental degradation. When you share items, you\'re preventing the need for new production, leading to fewer emissions. Our impact chart showcases the reduction in CO2 equivalents, allowing you to see the positive effect your choices have on the environment.'},
    {'title': 'Shared Items and Community Impact:', 'description':'Wura isn\'t just about the environment - it\'s about building a community focused on collaboration and support. Each item you share is an opportunity to help someone else while decluttering your space. The impact chart also highlights the number of items you\'ve shared, indicating how many people you\'ve helped find what they need and save money.'},
    ]
 
    return (
    <section className="impactSection">
        <div className='impactSubSection'>
          <div className='impactTextBody'>
            <div className='impactTitle1'>
            Welcome to Wura - Making a Positive Impact Together
            </div>
            <p className='impactBody'>
            At Wura, we believe that small actions can lead to big changes. Our mission is to empower you to make a meaningful impact on the environment and your community through the simple act of sharing. By participating in our item sharing app, you're not only contributing to a more sustainable world but also fostering a sense of community and collaboration.
            </p>
            <br/>
            <div className='impactTitle2 impactList'>
            Understanding Your Impact:
            </div>
            <div className='impactList'>
            <ol>
              {impacts.map((item)=>{
              return (<li><span className='impactTitle3'>{item.title}</span><br/>{item.description}<br/><br/></li>);
              })}
            </ol>
            </div>
          </div>

          <CumGraph/>
        </div>
    </section>
  );
}

export default Impacts;
