import React from 'react';
import './Privacy.css';


var dateUpdated =  'Last Updated: 22 July, 2023.';
var terms = [
           {title: 'What is Wura App?', description: 'Wura App is a platform that enables users to give away items they no longer need to others within their local community. It fosters generosity, reduces waste, and strengthens the sense of community by promoting free item sharing.'},
           {title: 'How does Wura App work?', description: 'Wura App connects individuals who have items they\'re willing to give away with those who are interested in receiving them. Users can list items they want to share for free, and others can express interest in these items.'},
           {title: 'Is Wura App available everywhere?', description: 'At present, Wura App is active in Nigeria, but accessible in everywhere. However, you only see items listed in your country. We are actively working on expanding our awareness to make more items available in different communities.'},
           {title: 'What types of items can be shared on Wura App?', description: 'Wura App supports the sharing of various items, including clothing, household goods, electronics, toys, and more. The key principle is that items are offered for free to promote generosity and community spirit.'},
           {title: 'How do I join Wura App?', description: 'To join Wura App, download the app from your device\'s app store, create an account, and start listing or expressing interest in items. You\'ll need to provide basic information and agree to our terms of use.'},
           {title: 'Is there any cost associated with using Wura App?', description: 'Wura App operates on the principle of free sharing. There are no monetary costs involved in using the platform. Users can give away items without any transactional requirements.'},
           {title: 'How do I list an item for sharing on Wura App?', description: 'To share an item, log in to your account, navigate to the "Share an Item" section, provide item details, upload photos, and indicate its availability. Users interested in the item can express their interest.'},
           {title: 'How do I express interest in an item?', description: 'If you\'re interested in receiving a listed item, search for it using keywords or categories. Once you find the item, express your interest. The item\'s owner will then communicate with you to arrange pickup or delivery.'},
           {title: 'How does an item gets picked up?', description: 'After sending a request and the giver accepts it, a message platiorm comes up. In there, both parties can discuss and agree on how and where to get the item.'},
           {title: 'What if an item I receive is not in the expected condition?', description: 'Wura App relies on the goodwill of users to offer items in good condition. If you receive an item that doesn\'t meet your expectations, we encourage open communication with the giver. Your feedback helps maintain the spirit of giving.'},
           {title: 'What does the impact data tells you?', description: 'This essentially gives you information on how much CO2 emission, and water you have saved by sharing your items with other people.'},
           {title: 'How is my impact data generated?', description: 'After giving or receiving an item, the item name, category and other factors are fed into our machine learning model which contains an impact data dictionary.'},
           {title: 'How is the impact data dictionary formulated?', description: 'In every category, such as food, electronics, clothings, plastics, sample data are gotten from several data banks such as statista (money - data), water - calculator (water - data), ethical consumer (emissions - data), my - toolshed ( emissions - data) etc. Furthermore, as we collate items shared on Wura\'s platform, we update the catalogue, thus improving the model accuracy.'},
           {title: 'Is my personal information secure on Wura App?', description: 'Wura App prioritizes user privacy and security. Your personal details are safeguarded using encryption and industry-standard security measures. Only essential information is shared during the sharing process.'},
           {title: 'How can I provide feedback about the sharing experience?', description: 'Your feedback is valuable! You can share your thoughts directly through the app or on our website. Your suggestions aid us in enhancing the platform\'s usability and the overall sharing experience.'},
           {title: 'Can I recommend adding my community to Wura App?', description: 'Certainly! We welcome community recommendations for future expansion. Feel free to contact us with information about your community, and we\'ll consider it for potential inclusion.'},
           {title: 'How do I stay informed about updates to Wura App?', description: 'To stay up-to-date, follow us on our social media channels @wearewura and subscribe to our newsletter. We regularly share news, tips, and updates about the app\'s features and the sharing community.'},
           {title: 'Is Wura App only accessible via mobile app?', description: 'Certainly! We welcome community recommendations for future expansion. Feel free to contact us with information about your community, and we\'ll consider it for potential inclusion.'},
           ];



const FAQ = () => {
  return (

    <div className="privacySection">
      <h1>FAQs for Wura</h1>
      <p>
       {dateUpdated}
      </p>
      <p>
      Welcome to the Wura App FAQ - Your guide to embracing generosity, reducing waste, and building community through free item sharing.
     </p>
      <br/>
      
     
      <div>
      <ol>
      {terms.map((item)=>{
       return (<li><span className='title'>{item.title}</span><br/>{item.description}<br/><br/></li>);
      })}
      </ol>
      </div>
    
   
      <p className='title'>
      Contact Us:
      </p>
      <div>
        <p>
        For further questions or inquiries, please don't hesitate to contact our support team through the app or our<a href='mailto:hello@wura.app' className='link'>website</a>
        </p>
        <p>
        Thank you for choosing Wura! We are committed to creating a safe and vibrant community for eco-friendly lovers while safeguarding your privacy.
        </p>
      </div>
      </div>
  );
};

export default FAQ;
