import React from 'react';
import './reviewcard.css'; // Import your CSS styles for the ReviewCard

const ReviewCard = ({ user, item, review, date }) => {
  return (
    <div className="review-card">

      <div className="item-section">
        <img src={item.image} alt={item.name} className="item-image" />
        <p className="review-content">{review}</p>
      </div>
      <div className="user-section">
        <div className="user-profile">
          <img src={user.profilePicture} alt={`${user.name}'s profile`} />
          <span className="user-name">{user.name}</span>
        </div>
      </div>
      <div className="rating-section">
        <span className="review-date">{date}</span>
      </div>
    
    </div>
  );
};

export default ReviewCard;
