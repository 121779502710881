 import React from 'react';
import './Privacy.css';


var dateUpdated =  'Last Updated: 22 July, 2023.';
var infoCollect = [{title: 'Personal Information', description: 'This Privacy Policy applies to our application named Wura. Our goal is to make giving a lifestyle by promoting a sharing economy. Wura is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used and disclosed by Wura. By accessing or using our Service, you signify that you have read, understood and agree to our collection, storage, use and disclosure of your personal information as described in this Privacy Policy.'},
            {title: 'Log Data', description: 'when you use Wura, our servers record information "log data" including information that your browser automatically sends whenever you visit a website or that your mobile app automatically sends when using it. Every device connected to the internet is assigned a number known as an internet protocol "IP" address, these numbers are usually assigned in geographic blocks. This log data includes your internet protocol address which we use to infer your exact location.'},
            {title: 'Cookie Data', description: 'We use "cookies" which are described as small text files sent by your computer each time you visit our website and mobile app, peculiar to your Wura account or your browser or similar technologies to get log data. When we use cookies or other similar technologies, we use "session cookies" that last until you close your browser, mobile apps or " persistent cookies" that last until you or your browser delete them.'},
            {title: 'Device Information', description: 'this is additional to log data, we collect information about the devices you are using Wura on, including the type of device, operating system, settings, unique device identifiers and crash data that helps us understand when something breaks.'},
            {title: 'Third party software/services', description: 'We use the following third party software/services to collect and process your personal data - Google Fire services including Crashlytics, Firebase.'},
            ];
var infoUse = [{title: 'Identification', description: 'Identify you when you use Wura.'},
            {title: 'Recommendation', description: 'Suggest items you might like based on your activity on Wura.'},
            {title: 'Security and Integrity', description: 'We utilize your data to ensure the security, integrity, and overall reliability of Wura, preventing fraudulent activities and protecting both users and the platform.'},
            {title: 'Analytics', description: 'Conduct analytics and research on who is using Wura and what they are doing.'},
            {title: 'Personalization', description: 'Identify your location and customize items to show you.'},
            {title: 'Improvements', description: 'Improve Wura and offer new features.'},

           
            ];
var infoData = [{title: 'Wura Community', description: 'Your username and profile picture, along with item listing details, are visible to other users within the Wura community in your location.'},
            {title: 'Service Providers', description: 'We may share your information with trusted third-party service providers who assist us in operating our platform, providing customer support, and analyzing user data. These providers are bound by confidentiality agreements and are only allowed to use your data for the purposes specified by us.'},
            {title: 'Legal Compliance', description: 'We may disclose your information to comply with applicable laws, regulations, legal processes, or government requests.'},
            ];
var infoPrivacy = [{title: 'Account Information', description: 'You have the right to review, update, or delete your personal information by accessing your account settings on Wura.'},
                    {title: 'Communication Preferences', description: 'You can opt-out of receiving non-essential communications from us by adjusting your notification preferences in your account settings.'},
                   ];
var dataSecurity = [{title: 'Data Security:', description: 'We implement industry-standard security measures to protect your information from unauthorized access, loss, or misuse. However, please understand that no method of transmission over the internet or electronic storage is 100% secure.'},
                  ];
var changes = [{title: 'Changes to this Privacy Policy:', description: 'We may update this Privacy Policy from time to time. We will notify you of any material changes via email or through prominent notices on the Wura website.'},
                ];


const PrivacyPolicy = () => {

  return (
    <div className="privacySection">

      <h1>Privacy Policy for Wura</h1>
      <p>
       {dateUpdated}
      </p>

      <p>
        Welcome to Wura! We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard the data you provide to us when using our website and services. By accessing or using Wura App, you consent to the practices described in this policy.
      </p>
      <br/>
      <p className='title'>
      Information We Collect:
      </p>
      <div>
      <ol>
      {infoCollect.map((item)=>{
       return (<li><span className='title'>{item.title}</span>: {item.description}<br/><br/></li>);
      })}
      </ol>
      </div>
      <br/>
      <p className='title'>
      How We Use Your Information:
      </p>
      <div>
      <ol>
      {infoUse.map((item)=>{
       return (<li><span className='title'>{item.title}</span>: {item.description}<br/><br/></li>);
      })}
      </ol>
      </div>
      <br/>
      <p className='title'>
      Data Sharing and Disclosure:
      </p>
      <div>
      <ol>
      {infoData.map((item)=>{
       return (<li><span className='title'>{item.title}</span>: {item.description}<br/><br/></li>);
      })}
      </ol>
      </div>
      <br/>
      <p className='title'>
      Your Privacy Choices:
      </p>
      <div>
      <ol>
      {infoPrivacy.map((item)=>{
       return (<li><span className='title'>{item.title}</span>: {item.description}<br/><br/></li>);
      })}
      </ol>
      </div>
      <br/>
      <p className='title'>
      Data Security:
      </p>
      <div>
        {dataSecurity[0].description}
      </div>
      <br/>
      <p className='title'>
      Changes to this Privacy Policy:
      </p>
      <div>
        {changes[0].description}
      </div>
      <br/>
      <p className='title'>
      Contact Us:
      </p>
      <div>
        <p>
        If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at<a href='mailto:hello@wura.app' className='link'>hello@wura.app</a>
        </p>
        <p>
        Thank you for choosing Wura App! We are committed to creating a safe and vibrant community for eco-friendly lovers while safeguarding your privacy.
        </p>
      </div>
      </div>
  );
};

export default PrivacyPolicy;
