import React from 'react';
import './Privacy.css';


var dateUpdated =  'Last Updated: 22 July, 2023.';
var terms = [{title: 'Eligibility', description: 'You must be at least 18 years old to use Wura App. By using the platform, you represent and warrant that you are of legal age and have the right to enter into this agreement.'},
            {title: 'User Accounts', description: 'To access certain features of Wura, you may be required to create a user account. You are solely responsible for maintaining the confidentiality of your account credentials and for all activities conducted under your account.'},
            {title: 'Item Listings and Sharing', description: 'Wura allows users to list items they want to give our to their immediate community and communicate with other users for potential sharing. By listing an item for sharing, you affirm that you are the rightful owner of the item and have the authority to share it.'},
            {title: 'User Conduct', description: 'You agree to use Wura for lawful purposes only and to abide by all applicable laws and regulations. You must not engage in any activities that may harm, disrupt, or compromise the integrity of Wura or its users.'},
            {title: 'Prohibited Content', description: 'You must not post or share any content that is illegal, offensive, abusive, defamatory, discriminatory, or infringing on third-party rights. Wura reserves the right to remove or block any content that violates these Terms.'},
            {title: 'Privacy', description: 'Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.'},
            {title: 'Intellectual Property', description: 'All content and materials on Wura, including but not limited to logos, text, images, and software, are protected by copyright and other intellectual property laws. You may not use, modify, reproduce, distribute, or create derivative works based on Wura\'s content without our explicit written permission.'},
            {title: 'Third-Party Links', description: 'Wura may contain links to third-party websites or services. We are not responsible for the content or practices of these external sites. Your interactions with third-party websites are at your own risk, and you should review their terms and privacy policies before engaging with them.'},
            {title: 'Disclaimer of Warranties', description: 'Wura is provided "as is" without warranties of any kind, whether express or implied. We do not guarantee the accuracy, reliability, or availability of the platform or its content. You use Wura at your own risk.'},
            {title: 'Limitation of Liability', description: 'In no event shall Wura or its affiliates be liable for any indirect, consequential, incidental, or special damages arising out of or in connection with the use of the platform or the inability to access it.'},
            {title: 'Indemnification', description: 'You agree to indemnify and hold Wura, its officers, directors, employees, and agents harmless from any claims, damages, or liabilities arising from your use of the platform or any violation of these Terms.'},
            {title: 'Changes to the Terms', description: 'Wura reserves the right to modify or update these Terms at any time. Any material changes will be communicated to you through prominent notices on the website or via email. Continued use of Wura after such changes constitutes your acceptance of the revised Terms.'},
            {title: 'Termination', description: 'Wura may terminate your access to the platform or suspend your account at any time and for any reason, without prior notice.'},
            {title: 'Governing Law', description: 'These Terms shall be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflicts of law principles.'},
             ];



const Terms = () => {
  return (

    <div className="privacySection">
      <h1>Terms and Conditions for Wura</h1>
      <p>
       {dateUpdated}
      </p>

      <p>
      Welcome to Wura! These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or using Wura, you agree to comply with these Terms. If you do not agree with any part of these Terms, please refrain from using our platform.
      </p>
      <br/>
     
      <div>
      <ol>
      {terms.map((item)=>{
       return (<li><span className='title'>{item.title}</span><br/>{item.description}<br/><br/></li>);
      })}
      </ol>
      </div>
    
   
      <p className='title'>
      Contact Us:
      </p>
      <div>
        <p>
        If you have any questions or concerns about these Terms and Conditions, please contact us at <a href='mailto:hello@wura.app' className='link'>hello@wura.app</a>
        </p>
        <p>
        Thank you for choosing Wura! We are committed to creating a safe and vibrant community for eco-friendly lovers while safeguarding your privacy.
        </p>
      </div>
      </div>
  );
};

export default Terms;
