import React from 'react';
import './Privacy.css';


var dateUpdated =  'Last Updated: 29 November, 2023.';
var terms = [{title: 'Eligibility', description: 'The campaign is open to all individuals aged 18 and above, residing in Nigeria. Employees, affiliates of Wura and its associated partners are not eligible to participate.'},
            {title: 'Campaign Period:', description: 'The Wura December Gift-A-Thon campaign begins on November 30th, 2023 and ends on December 31st, 2023.'},
            {title: 'Participation', description: 'To qualify for the SPAR voucher prize, participants must donate a minimum of three items through the Wura platform during the campaign period.\nEach participant will be considered eligible for the prize draw upon meeting the donation criteria.'},
            {title: 'Prize Details', description: 'The prize is a N50,000 SPAR voucher. \nThe winner will be selected randomly from eligible participants who have donated three or more items.\nThe winner will be notified via the Wura App. \nThe SPAR voucher is non-transferable and cannot be exchanged for cash or other alternatives.'},
            {title: 'Winner Announcement', description: 'The winner\'s name will be announced on Wura\'s official social media platforms and website.\nBy participating, participants grant permission for their names to be used in promotional materials.'},
            {title: 'Verification and Disqualification', description: 'Wura reserves the right to verify the authenticity of donations and disqualify any participant found to be engaging in fraudulent or unethical behaviour.\nAny misrepresentation of donations or breach of terms will lead to immediate disqualification.'},
            {title: 'Data Privacy', description: 'Participants personal information collected during the campaign will be used solely for campaign-related purposes and will not be shared with third parties without consent.'},
            {title: 'General Conditions', description: 'Wura reserves the right to modify, suspend, or cancel the campaign or its terms at any time without prior notice.\nBy participating, participants agree to abide by the terms and conditions outlined.'},
            {title: 'Legal Jurisdiction', description: 'The campaign and its terms are governed by the laws of Nigeria and any disputes will be subject to the exclusive jurisdiction of the courts in that jurisdiction.\nBy participating in the Wura December Gift-A-Thon, participants acknowledge that they have read, understood, and agreed to these terms and conditions.'},
          ];



const Promo = () => {
  return (

    <div className="privacySection">
      <h1>Wura December Gift-A-Thon - Terms and Conditions</h1>
      <p>
       {dateUpdated}
      </p>

      <p>
      Welcome to Wura! These Terms and Conditions ("Terms") serve as the governing rules for the promotional campaign conducted on our platform.
      </p>
      <br/>
     
      <div>
      <ol>
      {terms.map((item)=>{
       return (<li><span className='title'>{item.title}</span><br/>{item.description}<br/><br/></li>);
      })}
      </ol>
      </div>
    
   
      <p className='title'>
      Contact Us:
      </p>
      <div>
        <p>
        If you have any questions or concerns about these Terms and Conditions, please contact us at <a href='mailto:hello@wura.app' className='link'>hello@wura.app</a>
        </p>
        <p>
        Thank you for choosing Wura! We are committed to creating a safe and vibrant community for eco-friendly lovers while safeguarding your privacy.
        </p>
      </div>
      </div>
  );
};

export default Promo;
