import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Bar, BarChart, ResponsiveContainer, Radar, PolarAngleAxis, PolarRadiusAxis, RadarChart, PolarGrid} from 'recharts';
import './CumGraph.css';
import GoogleMapReact from 'google-map-react';
import { Tooltip as ReactToolTip } from 'react-tooltip'

function ChartLine1(){
    const [data, setData] = useState([]);
    useEffect(() => {
      // Fetch data from the API
      axios.get('https://flask-fire-qu3rhi3y7a-ew.a.run.app/impacts')
        .then(response => {
          setData(response.data.data); // Update the state with fetched data
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }, []); // Empty dependency array means the effect runs only once after the initial render
  const res = data.length >0 ? data[data.length-1]['ghg']: '';
  //const data = [{x: 'A', y: 400}, {x: 'B', y: 800}, {x: 'C', y: 560}, {x: 'D', y: 120}];
  return (
    <div className='graph' style={{padding: '1rem'}}>
        <h2 className='chartTitle'>GHG Saved
        <div>
        <a id="my-anchor-element" className='anchor-info'  data-tooltip-variant="info"><span>&#9432;</span> </a>
       
        <ReactToolTip anchorSelect="#my-anchor-element" style={{zIndex: 9999,  width: '300px', fontSize: '16px' }} place="top">
        The cumulative GHG saved represents the collective efforts of Wura's community in reducing greenhouse gas emissions. 
        <br/>By sharing and reusing items, our users have contributed to preventing the release of {res}kg of CO2 equivalents into the atmosphere.
       <br/> Your role in this achievement is pivotal. Together, we're fostering sustainability and creating a brighter future for our planet.
       </ReactToolTip>
        </div>
        </h2>
      
      <ResponsiveContainer aspect={1.76}> 
        <LineChart
        data={data}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
        <YAxis dataKey="ghg"/>
        <XAxis dataKey="date" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="date" stroke="#ff7300" />
        <Line type="monotone" dataKey="ghg" stroke="#ff7300" />
        <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

function ChartLine2(){
  const [data, setData] = useState([]);
  useEffect(() => {
    // Fetch data from the API
    axios.get('https://flask-fire-qu3rhi3y7a-ew.a.run.app/impacts')
      .then(response => {
        console.log(response.data.data);
        setData(response.data.data); // Update the state with fetched data
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array means the effect runs only once after the initial render
  const res = data.length >0 ? data[data.length-1]['water']: '';
 
//const data = [{x: 'A', y: 400}, {x: 'B', y: 800}, {x: 'C', y: 560}, {x: 'D', y: 120}];
return (
  <div className='graph' style={{padding: '1rem'}}>
      <h2 className='chartTitle'>
        Water Saved
          <div>
          <a id="my-anchor-element-2" className='anchor-info'  data-tooltip-variant="info"><span>&#9432;</span></a>
          <ReactToolTip anchorSelect="#my-anchor-element-2" style={{width: '300px', fontSize: '16px' }} place="top">
          This graph illustrates the collective impact of Wura users over the years in conserving water resources. 
          <br/>The {res} cubic meters of water saved showcases our shared commitment to sustainability and responsible consumption.
          <br/>Your involvement matters and contributes to the broader goal of protecting our planet's vital resources.
          </ReactToolTip>
          </div>
        </h2>
    <ResponsiveContainer aspect={1.76}> 
      <LineChart
      data={data}
      margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
      <YAxis dataKey="water"/>
      <XAxis dataKey="date" />
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
      <Line type="monotone" dataKey="date" stroke="#ff7300" />
      <Line type="monotone" dataKey="water" stroke="#ff7300" />
      <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  </div>
);
}

function ChartRadar(){

  const [data, setData] = useState([]);
  useEffect(() => {
    // Fetch data from the API
    axios.get('https://flask-fire-qu3rhi3y7a-ew.a.run.app/itemsCategory')
      .then(response => {
        setData(response.data.data); // Update the state with fetched data
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array means the effect runs only once after the initial render

  var maxRadius = 150;
  if (window.innerWidth <= 800) {
    maxRadius = 80;
  } else {
    maxRadius = 150;
  }

  const maxValue = Math.max(...data.map(e=>e.quantity));
  return (    
      <div className='graph' style={{padding: '1rem'}}>
      <h2 className='chartTitle'>
          Items Shared
          <div>
          <a id="my-anchor-element-3" className='anchor-info'  data-tooltip-variant="info"><span>&#9432;</span></a>
          <ReactToolTip anchorSelect="#my-anchor-element-3" style={{width: '300px', fontSize: '16px' }} place="top">
          This figure represents the total number of items shared within each category. 
          <br/>It's a reflection of the collective effort of our community members to pass on belongings they no longer need, promoting a circular economy.          
          <br/>By sharing items, we work together to make a positive difference for both our environment and society as a whole.
          </ReactToolTip>
          </div>
        </h2>
    <ResponsiveContainer aspect={1.76}> 
      <RadarChart outerRadius={maxRadius}  data={data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="category" />
        <PolarRadiusAxis angle={30} domain={[0, maxValue]} />
        <Radar name="Category" dataKey="category" stroke="#132C47" fill="#E3B679" fillOpacity={0.6} />
        <Radar name="Quantity Shared" dataKey="quantity" stroke="#132C47" fill="#E3B679" fillOpacity={0.6} />
        <Tooltip />
      </RadarChart>
    </ResponsiveContainer>
  </div>
  );
}

function BarChartLocation(){

  const [data, setData] = useState([]);
  useEffect(() => {
    // Fetch data from the API
    axios.get('https://flask-fire-qu3rhi3y7a-ew.a.run.app/locationlistChart')
      .then(response => {
        setData(response.data.data); // Update the state with fetched data
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array means the effect runs only once after the initial render

  return (
    <div className='graph' style={{padding: '1rem'}}>
        <h2 className='chartTitle'>
           Cities with the Top Sharers
          <div>
          <a id="my-anchor-element-4" className='anchor-info'  data-tooltip-variant="info"><span>&#9432;</span></a>
          <ReactToolTip anchorSelect="#my-anchor-element-4" style={{width: '300px', fontSize: '16px' }} place="top">
          The bar graph represents the name of the city that stands out as a top contributor to our sharing community,
          <br/>and the number of individuals within that city who actively participate in sharing items with others.         
          <br/>As you explore the bar graph showcasing the Cities with Top Sharers, you're not just seeing data – you're witnessing the influence of individuals who prioritize community, sustainability, and a better world for us all
          </ReactToolTip>
          </div>
        </h2>
    <ResponsiveContainer aspect={1.76}> 
      <BarChart height={150}  data={data} width={500} >
       <XAxis dataKey="location" />
       <YAxis dataKey="users" />
      <Bar dataKey="users" fill="#E3B679" />
      <Bar dataKey="location" fill="#E3B679" />
      <Tooltip />
      </BarChart>
    </ResponsiveContainer>
  </div>
  );
}


function calculateCentroid(coordinates) {
  if (coordinates.length === 0) {
    return null;
  }

  const numCoords = coordinates.length;

  let x = 0;
  let y = 0;
  let z = 0;

  for (const coord of coordinates) {
    const latRad = (coord.lat * Math.PI) / 180;
    const lngRad = (coord.lng * Math.PI) / 180;

    x += Math.cos(latRad) * Math.cos(lngRad);
    y += Math.cos(latRad) * Math.sin(lngRad);
    z += Math.sin(latRad);
  }

  x /= numCoords;
  y /= numCoords;
  z /= numCoords;

  const centerLng = Math.atan2(y, x) * (180 / Math.PI);
  const centerLat = Math.atan2(z, Math.sqrt(x * x + y * y)) * (180 / Math.PI);

  return { lat: centerLat, lng: centerLng };
}




function LocationMap(){
                      
  const [data, setData] = useState([]);
  useEffect(() => {
    // Fetch data from the API
    axios.get('https://flask-fire-qu3rhi3y7a-ew.a.run.app/locationlist')
      .then(response => {
        setData(response.data.data); // Update the state with fetched data
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); 


  const centroid = data.length === 0? [0,0]: calculateCentroid(data);
  const mapProps = {
    center:  centroid,
    zoom: 4,
  };    
  const heatMapData = {
    positions: data.map((location) => ({
      lat: location.lat,
      lng: location.lng,
    })),
    options: {
      radius: 20,
      opacity: 0.6,
    },
  };
  return (
    <div className='graph' style={{ height: '50vh', width: '100%',  borderRadius: '12px', overflow: 'hidden' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyBCuy1I2inGqioBPqY9U7B67bD0dEUAjIo" }}
      defaultCenter={mapProps.center}
      defaultZoom={mapProps.zoom}
      heatmapLibrary={true}          
      heatmap={heatMapData}  
    >
     
    </GoogleMapReact>
  </div>
  );
}

function CalcStats(category){
  const [data, setData] = useState([]);
  useEffect(() => {
    // Fetch data from the API
    var url = '';
    if (category === 'place'){
      url = 'https://flask-fire-qu3rhi3y7a-ew.a.run.app/location';
    }else if (category === 'items'){
      url = 'https://flask-fire-qu3rhi3y7a-ew.a.run.app/items';
    }else if (category === 'water'){
      url = 'https://flask-fire-qu3rhi3y7a-ew.a.run.app/water';
    }else if (category === 'ghg'){
      url = 'https://flask-fire-qu3rhi3y7a-ew.a.run.app/ghg';
    }
    axios.get(url)
      .then(response => {
        setData(response.data.data); // Update the state with fetched data
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); 
  const res = data.toLocaleString('en-US')
  return (<div>{res}</div>);
}

function stats(){

  const statsData = [{'type': 'Cities Impacted', 'category': 'place', 'unit': ''}, 
                    {'type': 'Items Shared', 'category': 'items', 'unit': ''}, 
                    {'type': 'Water Saved', 'category': 'water', 'unit': 'm³'}, 
                    {'type': 'GHG Prevented', 'category': 'ghg', 'unit': 'kg CO2 eq'}];
  return (
    <div className='groupGraphText'>
    {statsData.map((e)=>{
      return  <div className='graphTextContainer'>
                  <h4>{e.type}</h4>
                  <h1>{CalcStats(e.category)}</h1>
                  <h5 style={{padding: '0px', marginTop: '2px', marginBottom: '0'}}>{e.unit}</h5>
              </div>
    })}
    </div>
  );
}

function CumGraph() {
  return (
    <div className='graphContainer'>
      {stats()}
      {ChartLine1()}
      {ChartLine2()}
      {ChartRadar()}
      {BarChartLocation()}
      {LocationMap()}
    </div>
  );

}

export default CumGraph;
