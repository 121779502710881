import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Home.css';
import phoneImage from './mobile-phone.png';
import playstore from './PlayStorebutton.png';
import appstore from './AppStorebutton.png';
import techpointImage from './techpoint.png';
import vangImage from './vanguardimg.png';
import ReviewCard from '../components/reviewcard';
import wuraicon from './wuraicon.png';
import promo from './hackathon.png';
import { Link } from 'react-router-dom';

function ReviewView(){

const [data, setData] = useState([]);
useEffect(() => {
  // Fetch data from the API
  axios.get('https://flask-fire-qu3rhi3y7a-ew.a.run.app/reviews')
    .then(response => {
      setData(response.data.data); // Update the state with fetched data
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
}, []); // Empty dependency array means the effect runs only once after the initial render

  return (  
    <div className='reviewSection'>
  { data.map((r)=>{
    return  <ReviewCard
          user={r.user}
          giver={r.giver}
          item={r.item}
          review={r.review}
          rating={r.rating}
          date={r.date}
        />
    })}
    </div>
 
  );
}

function Home() {

  return (
    <section className="homeSection">
        <div className='text-container'>
            <div className='text-large'>
            <img style={{width: '180px', height: '48px'}} src={wuraicon} alt='wura'/> - the easiest way to share items     
            </div>
            <div className='text-small'>
            Imagine a world where sharing is effortless. Wura is the bridge to that future. 
            <br/><br/>Our app connects communities by enabling users to share everyday items, from tools to electronics, fostering a culture of sustainability and collaboration. 
            With an intuitive interface, secure transactions, and a focus on building trust, Wura redefines ownership. 
            <br/><br/>Join us in revolutionizing the way we access resources, reducing waste, and nurturing stronger neighborhood connections, one shared item at a time.
            </div>
            <div className='downloadButton'>
            <a href='https://play.google.com/store/apps/details?id=app.wura' target='_blank' rel="noreferrer"><img src={playstore} alt='playstore'/></a>
            <a href='https://apps.apple.com/gb/app/wura/id1591707388' target='_blank' rel="noreferrer"><img src={appstore} alt='appstore'/></a>
            </div>
        </div>
        <div className='phoneImage'>
            <img src={phoneImage} alt='phone'/>
        </div>

     {/*    <div className='press-container'>
          <div className='press-container-header'>Wura Gift-A-Thon</div>
          <a href='https://wura.app/link/mobile'><div className='press-image-container'>
          <img style={{width: '100%', height: '100%'}} src={promo} alt='wura hackathon'/>
          </div ></a>
          <div className='promo-container'>
          <br/> <br/>
          <p> <span style={{fontWeight: 'bold'}}>Join Wura's Gift-A-Thon: Share, Win, and Spread Joy!</span></p>
          <p>This festive season, be a part of something incredible! Share the spirit of giving by gifting at least three items through Wura's platform. <br/>Your act of kindness not only brings joy but also enters you into the draw to win a fantastic N50,000 SPAR voucher!
        
          <br/> <br/><br/> <span style={{fontWeight: 'bold'}}> Why Participate?</span> 
          <div className='list'>
          <ul style={{textAlign:'left'}}>
            <li>  Spread Joy: Share items you no longer need and brighten someone's day.</li>
            <li>   Win Big: Each three-item donation qualifies you for the chance to win the grand SPAR voucher.</li>
            <li>   Make an Impact: Join a community-driven movement promoting sharing and reducing waste.</li>
          </ul>
          </div>
          <br/>
         

       <span style={{fontWeight: 'bold'}}> How to Join:</span> 
       <div className='list'>
       <ul style={{textAlign:'left'}}>
            <li>   Sign up or log in to Wura on our mobile app.</li>
            <li>      Share a minimum of three items you're willing to gift.</li>
            <li>   Your generosity automatically enters you into the SPAR voucher draw!</li>
            <li>   Ready to make a difference and win big? Start sharing today!</li>
          </ul>
          </div>
          <br/>
          <a className='promo-link' href='https://wura.app/link/mobile/' target='_blank' rel="noreferrer">Join Now</a> 
          | 
          <Link to="/giftathon" className='promo-link'>Terms and Conditions</Link>
          </p>
          </div>
        </div>
 */}
        <div className='press-container'>
          <div className='press-container-header'>Reviews</div>
          <div className='press-image-container'>
          <ReviewView/>
          </div>
        </div>


        <div className='press-container'>
        <div className='press-container-header'>In the press</div>
          <div className='press-image-container'>
          <div className='press-image'>  
            <a href='https://www.vanguardngr.com/2023/01/founder-reckons-environmental-protection-impact-made-by-wura-tech/' target='_blank' rel="noreferrer"><img src={vangImage} alt='vanguard news'/></a>
          </div>
          <div className='press-image'>  
            <a href='https://techpoint.africa/2022/03/31/how-to-reuse-waste-in-nigeria/' target='_blank' rel="noreferrer"><img src={techpointImage} alt='techpoint news'/></a>
          </div>
          </div>
       
        </div>
    </section>
  );
}

export default Home;
